import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import dashboardSaga from './dashboard/saga';
import mrAgentSaga from './mrAgent/saga';
import locationSaga from './locationReport/saga';
import attendanceSaga from './attendanceReport/saga';
import callSaga from './callReport/saga';
import doctorSaga from './doctor/saga';
import visitSaga from './visit/saga';
import smsSaga from './sms/saga';
import tenantSaga from './tenant/saga';
import activelocationSaga from './activelocation/saga';
export default function* rootSaga(getState) {
    yield all([authSaga(), mrAgentSaga(), tenantSaga(),attendanceSaga(),callSaga(), activelocationSaga(), visitSaga(), layoutSaga(), appMenuSaga(), dashboardSaga(), smsSaga(), doctorSaga(), locationSaga()]);
}
