import { EMPTY_MRAGENT_FAILED, MRAGENT_DATA_SUCCESS, MRAGENT_DATA } from './constants';
import { POST_EMPTY_MRAGENT_FAILED, POST_MRAGENT_DATA_SUCCESS, POST_MRAGENT_DATA } from './constants';
import { EDIT_EMPTY_MRAGENT_FAILED, EDIT_MRAGENT_DATA_SUCCESS, EDIT_MRAGENT_DATA } from './constants';
import { DELETE_EMPTY_MRAGENT_FAILED, DELETE_MRAGENT_DATA_SUCCESS, DELETE_MRAGENT_DATA } from './constants';

const INIT_STATE = [];

const MrAgent = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MRAGENT_DATA:
            return { ...state, loading: true }; 
        case MRAGENT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case EMPTY_MRAGENT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case POST_MRAGENT_DATA:
            return { ...state, loading: true };
        case POST_MRAGENT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case POST_EMPTY_MRAGENT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case EDIT_MRAGENT_DATA:
            return { ...state, loading: true };
        case EDIT_MRAGENT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case EDIT_EMPTY_MRAGENT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DELETE_MRAGENT_DATA:
            return { ...state, loading: true };
        case DELETE_MRAGENT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case DELETE_EMPTY_MRAGENT_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export default MrAgent