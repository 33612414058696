import { CALLREPORT_DATA, CALLREPORT_DATA_SUCCESS ,CALLREPORT_DATA_FAILED} from './constants';

export const callReportData = (queryString) => ({
    type: CALLREPORT_DATA,
    payload: queryString,
});

export const callReportSuccess = (callReportData) => ({
    type: CALLREPORT_DATA_SUCCESS,
    payload: callReportData,
});

export const callReportFailed = (error) => ({
    type: CALLREPORT_DATA_FAILED,
    payload: error,
});