export const DOCTOR_DATA = 'DOCTOR_DATA';
export const DOCTOR_DATA_SUCCESS = 'DOCTOR_DATA_SUCCESS';
export const EMPTY_DOCTOR_FAILED = 'EMPTY_DOCTOR_FAILED';
export const POST_DOCTOR_DATA = 'POST_DOCTOR_DATA';
export const POST_DOCTOR_DATA_SUCCESS = 'POST_DOCTOR_DATA_SUCCESS';
export const POST_EMPTY_DOCTOR_FAILED = 'POST_EMPTY_DOCTOR_FAILED';
export const EDIT_DOCTOR_DATA = 'EDIT_DOCTOR_DATA';
export const EDIT_DOCTOR_DATA_SUCCESS = 'EDIT_DOCTOR_DATA_SUCCESS';
export const EDIT_EMPTY_DOCTOR_FAILED = 'EDIT_EMPTY_DOCTOR_FAILED';
export const DELETE_DOCTOR_DATA = 'DELETE_DOCTOR_DATA';
export const DELETE_DOCTOR_DATA_SUCCESS = 'DELETE_DOCTOR_DATA_SUCCESS';
export const DELETE_EMPTY_DOCTOR_FAILED = 'DELETE_EMPTY_DOCTOR_FAILED'