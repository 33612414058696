import { EMPTY_DOCTOR_FAILED, DOCTOR_DATA_SUCCESS, DOCTOR_DATA } from './constants'
import { POST_EMPTY_DOCTOR_FAILED, POST_DOCTOR_DATA_SUCCESS, POST_DOCTOR_DATA } from './constants'
import { EDIT_EMPTY_DOCTOR_FAILED, EDIT_DOCTOR_DATA_SUCCESS, EDIT_DOCTOR_DATA } from './constants'
import { DELETE_EMPTY_DOCTOR_FAILED, DELETE_DOCTOR_DATA_SUCCESS, DELETE_DOCTOR_DATA } from './constants'
const INIT_STATE = [];

const Doctor = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DOCTOR_DATA:
            return { ...state, loading: true };

        case DOCTOR_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };

        case EMPTY_DOCTOR_FAILED:
            return { ...state, error: action.payload, loading: false };

        case POST_DOCTOR_DATA:
            return { ...state, loading: true };

        case POST_DOCTOR_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };

        case POST_EMPTY_DOCTOR_FAILED:
            return { ...state, error: action.payload, loading: false };

        case EDIT_DOCTOR_DATA:
            return { ...state, loading: true };

        case EDIT_DOCTOR_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };

        case EDIT_EMPTY_DOCTOR_FAILED:
            return { ...state, error: action.payload, loading: false };

        case DELETE_DOCTOR_DATA:
            return { ...state, loading: true };

        case DELETE_DOCTOR_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };

        case DELETE_EMPTY_DOCTOR_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return state;

    }
};
export default Doctor;
