
const fetchJSON = async (url, options) => {
    try {
        const response = await fetch(url, options);
        if (response.status === 404) {
            return {error: '404 not found'}
        }
        if (!response.status === 200) {
            throw response.json();
        }
        const json = await response.json();
        return json;
    } catch (error) {
        throw error;
    }
};

export { fetchJSON };
