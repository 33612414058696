import { getLoggedInUser } from '../../helpers/authUtils';
let uuid = 1;

const assignIdAndParent = (menuItems, parentId) => {
    const loggedInUser = getLoggedInUser();
    menuItems = menuItems || [];
    menuItems.forEach((item, index, object) => {
        const id = item.id || uuid;
        uuid += 1;
        item.id = id;
        item.parentId = item.parentId || parentId;
        item.active = false;
        if (item.roles && !item.roles.includes(loggedInUser.role)) {
            object.splice(index, 1);
            uuid -= 1;
        }
        if (typeof item.children !== 'undefined' && !item.children.includes(loggedInUser.role)) {
            assignIdAndParent(item.children, id);
        }
    });
};

export default (menuItems) => {
    assignIdAndParent(menuItems);
    return menuItems;
};
