export const TENANT_DATA = 'TENANT_DATA';
export const TENANT_DATA_SUCCESS = 'TENANT_DATA_SUCCESS';
export const EMPTY_TENANT_FAILED = 'EMPTY_TENANT_FAILED';
export const POST_TENANT_DATA = 'POST_TENANT_DATA';
export const POST_TENANT_DATA_SUCCESS = 'POST_TENANT_DATA_SUCCESS';
export const POST_EMPTY_TENANT_FAILED = 'POST_EMPTY_TENANT_FAILED';
export const EDIT_TENANT_DATA = 'EDIT_TENANT_DATA';
export const EDIT_TENANT_DATA_SUCCESS = 'EDIT_TENANT_DATA_SUCCESS';
export const EDIT_EMPTY_TENANT_FAILED = 'EDIT_EMPTY_TENANT_FAILED';
export const DELETE_TENANT_DATA = 'DELETE_TENANT_DATA';
export const DELETE_TENANT_DATA_SUCCESS = 'DELETE_TENANT_DATA_SUCCESS';
export const DELETE_EMPTY_TENANT_FAILED = 'DELETE_EMPTY_TENANT_FAILED'