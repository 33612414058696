export const MRAGENT_DATA = 'MRAGENT_DATA';
export const MRAGENT_DATA_SUCCESS = 'MRAGENT_DATA_SUCCESS';
export const EMPTY_MRAGENT_FAILED = 'EMPTY_MRAGENT_FAILED';
export const POST_MRAGENT_DATA = 'POST_MRAGENT_DATA';
export const POST_MRAGENT_DATA_SUCCESS = 'POST_MRAGENT_DATA_SUCCESS';
export const POST_EMPTY_MRAGENT_FAILED = 'POST_EMPTY_MRAGENT_FAILED';
export const EDIT_MRAGENT_DATA = 'EDIT_MRAGENT_DATA';
export const EDIT_MRAGENT_DATA_SUCCESS = 'EDIT_MRAGENT_DATA_SUCCESS';
export const EDIT_EMPTY_MRAGENT_FAILED = 'EDIT_EMPTY_MRAGENT_FAILED';
export const DELETE_MRAGENT_DATA = 'DELETE_MRAGENT_DATA';
export const DELETE_MRAGENT_DATA_SUCCESS = 'DELETE_MRAGENT_DATA_SUCCESS';
export const DELETE_EMPTY_MRAGENT_FAILED = 'DELETE_EMPTY_MRAGENT_FAILED'