import { all, call, fork, takeLatest , put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { DASHBOARD_DATA } from './constants';
import { dashboardSuccess, dashboardFailed } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* dashboard() {
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/dashboard', options);
        yield put(dashboardSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(dashboardFailed(message));
        console.log(message);
    }
}

export function* watchdashboard() {
    yield takeLatest (DASHBOARD_DATA, dashboard);
}

function* dashboardSaga() {
    yield all([fork(watchdashboard)]);
}

export default dashboardSaga;