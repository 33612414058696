
import { CALLREPORT_DATA, CALLREPORT_DATA_SUCCESS, CALLREPORT_DATA_FAILED } from './constants';

const INIT_STATE = {};

const callReport = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'INITIAL_STATE':
            return { ...state,data:[] ,loading: true };
        case CALLREPORT_DATA:
            return { ...state, loading: true };
        case CALLREPORT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case CALLREPORT_DATA_FAILED:
            return { ...state, error: action.payload, loading: false, data: {} };
        default:
            return state;
    }
};
export default callReport;
