import React, { Component } from 'react';

/**
 * Renders the preloader
 */
class PreLoaderWidget extends Component {

    render() {
        return (
            <div style={{textAlign: 'center'}}> 
                    <div className="spinner-border" ></div>
                
            </div>
        )
    }
}

export default PreLoaderWidget;