import { PAGINATION_EMPTY_VISIT_FAILED, PAGINATION_VISIT_DATA_SUCCESS, PAGINATION_VISIT_DATA, GET_VISIT_DATA } from './constants';
import { GET_EMPTY_VISIT_FAILED, GET_VISIT_DATA_SUCCESS,  GET_VISIT_DATA_FAILED } from './constants';
import { POST_EMPTY_VISIT_FAILED, POST_VISIT_DATA_SUCCESS, POST_VISIT_DATA } from './constants';
import { EDIT_EMPTY_VISIT_FAILED, EDIT_VISIT_DATA_SUCCESS, EDIT_VISIT_DATA } from './constants';
import { DELETE_EMPTY_VISIT_FAILED, DELETE_VISIT_DATA_SUCCESS, DELETE_VISIT_DATA } from './constants';
export const visitData = (visitData) => ({
    type: PAGINATION_VISIT_DATA,
    payload: visitData,
});  

export const visitSuccess = (visitData) => ({
    type:PAGINATION_VISIT_DATA_SUCCESS,
    payload: visitData,
});

export const visitFailed = (error) => ({
    type: PAGINATION_EMPTY_VISIT_FAILED,
    payload: error,
});

export const getVisitData = (visitData) => ({
    type: GET_VISIT_DATA,
    payload: visitData,
});

export const getVisitSuccess = (visitData) => ({
    type: GET_VISIT_DATA_SUCCESS,
    payload: visitData,
});

export const getVisitFailed = (error) => ({
    type: GET_VISIT_DATA_FAILED,
    payload: error,
});

export const postVisitData = (visitData) => ({
    type: POST_VISIT_DATA,
    payload: visitData,
});

export const postVisitSuccess = (visitData) => ({
    type: POST_VISIT_DATA_SUCCESS,
    payload: visitData,
});

export const postVisitFailed = (error) => ({
    type: POST_EMPTY_VISIT_FAILED,
    payload: error,
});

export const editVisitData = (visitData) => ({
    type: EDIT_VISIT_DATA,
    payload: visitData,
});

export const editVisitSuccess = (visitData) => ({
    type: EDIT_VISIT_DATA_SUCCESS,
    payload: visitData,
});

export const editVisitFailed = (error) => ({
    type: EDIT_EMPTY_VISIT_FAILED,
    payload: error,
});

export const deleteVisitData = (visitData) => ({
    type: DELETE_VISIT_DATA,
    payload: visitData,
});

export const deleteVisitSuccess = (visitData) => ({
    type: DELETE_VISIT_DATA_SUCCESS,
    payload: visitData,
});

export const deleteVisitFailed = (error) => ({
    type: DELETE_EMPTY_VISIT_FAILED,
    payload: error,
});