import { all, call, fork, takeLatest , put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { ActiveLocation_DATA } from './constants';
import { POST_ActiveLocation_DATA } from './constants';
import { EDIT_ActiveLocation_DATA } from './constants';
import { DELETE_ActiveLocation_DATA } from './constants';
import { ActiveLocationFailed, ActiveLocationSuccess } from './actions';
import {  postActiveLocationSuccess } from './actions';
//import {postActiveLocationFailed, editActiveLocationFailed, editActiveLocationSuccess } from './actions';
//import { deleteActiveLocationFailed, deleteActiveLocationSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* ActiveLocation(req) {
    let payload = req.payload ? req.payload : ''
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/ActiveLocation' + payload, options);
        yield put(ActiveLocationSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(ActiveLocationFailed(message));
        console.log(message);
    }
}

export function* watchActiveLocation() {
    yield takeLatest (ActiveLocation_DATA, ActiveLocation);
}

function* ActiveLocationSaga() {
    yield all([fork(watchActiveLocation),]);
}

//Post api

function* postActiveLocation(req) {
    const body = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/ActiveLocation', options);
        yield put(postActiveLocationSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(ActiveLocationFailed(message));
        console.log(message);
    }
}
export function* watchPostActiveLocation() {
    yield takeLatest (POST_ActiveLocation_DATA, postActiveLocation);
}

export function* postActiveLocationSaga() {
    yield all([fork(watchPostActiveLocation),]);
}

//edit api
function* editActiveLocation(req) {
    const body = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'EDIT',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/ActiveLocation', options);
        yield put(postActiveLocationSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(ActiveLocationFailed(message));
        console.log(message);
    }
}
export function* watchEditActiveLocation() {
    yield takeLatest (EDIT_ActiveLocation_DATA, editActiveLocation);
}
export function* editActiveLocationSaga() {
    yield all([fork(watchEditActiveLocation),]);
}

//Delete api

function* deletetActiveLocation(req) {
    const body = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/ActiveLocation', options);
        yield put(postActiveLocationSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(ActiveLocationFailed(message));
        console.log(message);
    }
}
export function* watchDeleteActiveLocation() {
    yield takeLatest (DELETE_ActiveLocation_DATA, deletetActiveLocation);
}
export function* deleteActiveLocationSaga() {
    yield all([fork(watchDeleteActiveLocation),]);
}


export default ActiveLocationSaga;