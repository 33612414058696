// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeLatest  } from 'redux-saga/effects';
import { config } from '../../helpers/config'
import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER/*, REGISTER_USER, FORGET_PASSWORD*/ } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    // registerUserSuccess,
    // registerUserFailed,
    // forgetPasswordSuccess,
    // forgetPasswordFailed,
} from './actions';

const setSession = user => {
    let cookies = new Cookies()
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};

function* login({ payload: { email, password, tenant } }) {
    const options = {
        body: JSON.stringify({ email, password, tenant }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/signin', options);
        
        if(!response.error){
            yield put(loginUserSuccess(response));
            setSession(response);
            window.location.reload()
        }
        yield put(loginUserFailed(response.error));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
    }
}

function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        }); 
        yield put({  payload: false })
    } catch (error) { }
}

export function* watchLoginUser() {
    yield takeLatest (LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeLatest (LOGOUT_USER, logout);
}

// /**
//  * Register the user
//  */
// function* register({ payload: { fullname, email, password } }) {
//     const options = {
//         body: JSON.stringify({ fullname, email, password }),
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//     };

//     try {
//         const response = yield call(fetchJSON, '/users/register', options);
//         yield put(registerUserSuccess(response));
//     } catch (error) {
//         let message;
//         switch (error.status) {
//             case 500:
//                 message = 'Internal Server Error';
//                 break;
//             case 401:
//                 message = 'Invalid credentials';
//                 break;
//             default:
//                 message = error;
//         }
//         yield put(registerUserFailed(message));
//     }
// }

// /**
//  * forget password
//  */
// function* forgetPassword({ payload: { email } }) {
//     const options = {
//         body: JSON.stringify({ email }),
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//     };

//     try {
//         const response = yield call(fetchJSON, '/users/password-reset', options);
//         yield put(forgetPasswordSuccess(response.message));
//     } catch (error) {
//         let message;
//         switch (error.status) {
//             case 500:
//                 message = 'Internal Server Error';
//                 break;
//             case 401:
//                 message = 'Invalid credentials';
//                 break;
//             default:
//                 message = error;
//         }
//         yield put(forgetPasswordFailed(message));
//     }
// }

// export function* watchRegisterUser() {
//     yield takeLatest (REGISTER_USER, register);
// }

// export function* watchForgetPassword() {
//     yield takeLatest (FORGET_PASSWORD, forgetPassword);
// }

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser),/* fork(watchRegisterUser), fork(watchForgetPassword)*/]);
}

export default authSaga;