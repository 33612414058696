import { all, call, fork, takeLatest , put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { SMS_DATA } from './constants';
import { smsFailed, smsSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* sms(payload) {
    let path
    if (payload.payload == null) {
        path = '/sms'
    } else {
        path = '/sms' + payload.payload
    }
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + path, options);
        yield put(smsSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(smsFailed(message));
        console.log(message);
    }
}

export function* watchSms() {
    yield takeLatest (SMS_DATA, sms);
}

function* smsSaga() {
    yield all([fork(watchSms),]);
}

export default smsSaga;