
import { LOCATION_DATA, LOCATION_DATA_SUCCESS, LOCATION_DATA_FAILED } from './constants';

const INIT_STATE = {};

const location = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'INITIAL_STATE':
            return { ...state,data:[] ,loading: true };
        case LOCATION_DATA:
            return { ...state, loading: true };
        case LOCATION_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case LOCATION_DATA_FAILED:
            return { ...state, error: action.payload, loading: false, data: {} };
        default:
            return state;
    }
};
export default location;
