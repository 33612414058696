import { all, call, fork, takeLatest , put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import {TENANT_DATA} from './constants';
import {POST_TENANT_DATA} from './constants';
import {EDIT_TENANT_DATA} from './constants';
import {DELETE_TENANT_DATA} from './constants';
import { tenantFailed, tenantSuccess } from './actions';
import { postTenantFailed, postTenantSuccess } from './actions';
//import { editTenantFailed, editTenantSuccess } from './actions';
//import { deleteTenantFailed, deleteTenantSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* tenant() {
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/tenant', options);
        yield put(tenantSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(tenantFailed(message));
        console.log(message);
    }
}

export function* watchTenant() {
    yield takeLatest (TENANT_DATA, tenant);
}

function* postTenant(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body:  JSON.stringify(body),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/tenant', options);
        yield put(postTenantSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(tenantFailed(message));
        console.log(message);
    }
}
export function* watchPostTenant() {
    yield takeLatest (POST_TENANT_DATA, postTenant);
}

function* editTenant(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body:  JSON.stringify(body),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/tenant', options);
        if(response.error){
            yield put(postTenantFailed(response));
        }else{
            yield put(postTenantSuccess(response));
        }
        
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(tenantFailed(message));
        console.log(message);
    }
}
export function* watchEditTenant() {
    yield takeLatest (EDIT_TENANT_DATA, editTenant);
}

function* deletetTenant(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/user', options);
        yield put(postTenantSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(tenantFailed(message));
        console.log(message);
    }
}
export function* watchDeleteTenant() {
    yield takeLatest (DELETE_TENANT_DATA, deletetTenant);
}
function* tenantSaga() {
    yield all([fork(watchTenant),fork(watchPostTenant),fork(watchEditTenant),fork(watchDeleteTenant)]);
}

export default tenantSaga;