import {EMPTY_ActiveLocation_FAILED,ActiveLocation_DATA_SUCCESS,ActiveLocation_DATA} from './constants';
import {POST_EMPTY_ActiveLocation_FAILED,POST_ActiveLocation_DATA_SUCCESS,POST_ActiveLocation_DATA} from './constants';
import {EDIT_EMPTY_ActiveLocation_FAILED,EDIT_ActiveLocation_DATA_SUCCESS,EDIT_ActiveLocation_DATA} from './constants';
import {DELETE_EMPTY_ActiveLocation_FAILED,DELETE_ActiveLocation_DATA_SUCCESS,DELETE_ActiveLocation_DATA} from './constants';
const INIT_STATE = {};

const ActiveLocation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActiveLocation_DATA:
            return { ...state, loading: true };
        case ActiveLocation_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case EMPTY_ActiveLocation_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export const PostActiveLocation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_ActiveLocation_DATA:
            return { ...state, loading: true };
        case POST_ActiveLocation_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case POST_EMPTY_ActiveLocation_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export const EditActiveLocation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case EDIT_ActiveLocation_DATA:
            return { ...state, loading: true };
        case EDIT_ActiveLocation_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case EDIT_EMPTY_ActiveLocation_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};
 
export const DeleteActiveLocation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DELETE_ActiveLocation_DATA:
            return { ...state, loading: true };
        case DELETE_ActiveLocation_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case DELETE_EMPTY_ActiveLocation_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export default ActiveLocation;
