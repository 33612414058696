import { DASHBOARD_DATA, DASHBOARD_DATA_SUCCESS ,DASHBOARD_DATA_FAILED} from './constants';

export const dashboardData = (dashboardData) => ({
    type: DASHBOARD_DATA,
    payload: dashboardData,
});

export const dashboardSuccess = (dashboardData) => ({
    type: DASHBOARD_DATA_SUCCESS,
    payload: dashboardData,
});

export const dashboardFailed = (error) => ({
    type: DASHBOARD_DATA_FAILED,
    payload: error,
});