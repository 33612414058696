
import { PAGINATION_ATTENDANCE_DATA, PAGINATION_ATTENDANCE_DATA_SUCCESS, PAGINATION_ATTENDANCE_DATA_FAILED } from './constants';
import { GET_ATTENDANCE_DATA, GET_ATTENDANCE_DATA_SUCCESS, GET_ATTENDANCE_DATA_FAILED } from './constants';

const INIT_STATE = {};

const attendance = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'INITIAL_STATE':
            return { ...state, data: [], loading: true };
        case PAGINATION_ATTENDANCE_DATA:
            return { ...state, loading: true };
        case PAGINATION_ATTENDANCE_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case PAGINATION_ATTENDANCE_DATA_FAILED:
            return { ...state, error: action.payload, loading: false, data: {} };
        case GET_ATTENDANCE_DATA:
            return { ...state, loading: true };
        case GET_ATTENDANCE_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case GET_ATTENDANCE_DATA_FAILED:
            return { ...state, error: action.payload, loading: false, data: {} };
        default:
            return state;
    }
};
export default attendance;
