import { EMPTY_ActiveLocation_FAILED, ActiveLocation_DATA_SUCCESS, ActiveLocation_DATA } from './constants';
import { POST_EMPTY_ActiveLocation_FAILED, POST_ActiveLocation_DATA_SUCCESS, POST_ActiveLocation_DATA } from './constants';
import { EDIT_EMPTY_ActiveLocation_FAILED, EDIT_ActiveLocation_DATA_SUCCESS, EDIT_ActiveLocation_DATA } from './constants';
import { DELETE_EMPTY_ActiveLocation_FAILED, DELETE_ActiveLocation_DATA_SUCCESS, DELETE_ActiveLocation_DATA } from './constants';
export const ActiveLocationData = (ActiveLocationData) => ({
    type: ActiveLocation_DATA,
    payload: ActiveLocationData,
});

export const ActiveLocationSuccess = (ActiveLocationData) => ({
    type: ActiveLocation_DATA_SUCCESS,
    payload: ActiveLocationData,
});

export const ActiveLocationFailed = (error) => ({
    type: EMPTY_ActiveLocation_FAILED,
    payload: error,
});


export const postActiveLocationData = (ActiveLocationData) => ({
    type: POST_ActiveLocation_DATA,
    payload: ActiveLocationData,
});

export const postActiveLocationSuccess = (ActiveLocationData) => ({
    type: POST_ActiveLocation_DATA_SUCCESS,
    payload: ActiveLocationData,
});

export const postActiveLocationFailed = (error) => ({
    type: POST_EMPTY_ActiveLocation_FAILED,
    payload: error,
});

export const editActiveLocationData = (ActiveLocationData) => ({
    type: EDIT_ActiveLocation_DATA,
    payload: ActiveLocationData,
});

export const editActiveLocationSuccess = (ActiveLocationData) => ({
    type: EDIT_ActiveLocation_DATA_SUCCESS,
    payload: ActiveLocationData,
});

export const editActiveLocationFailed = (error) => ({
    type: EDIT_EMPTY_ActiveLocation_FAILED,
    payload: error,
});

export const deleteActiveLocationData = (ActiveLocationData) => ({
    type: DELETE_ActiveLocation_DATA,
    payload: ActiveLocationData,
});

export const deleteActiveLocationSuccess = (ActiveLocationData) => ({
    type: DELETE_ActiveLocation_DATA_SUCCESS,
    payload: ActiveLocationData,
});

export const deleteActiveLocationFailed = (error) => ({
    type: DELETE_EMPTY_ActiveLocation_FAILED,
    payload: error,
});