import {EMPTY_MRAGENT_FAILED,MRAGENT_DATA_SUCCESS,MRAGENT_DATA} from './constants';
import {POST_EMPTY_MRAGENT_FAILED,POST_MRAGENT_DATA_SUCCESS,POST_MRAGENT_DATA} from './constants';
import {EDIT_EMPTY_MRAGENT_FAILED,EDIT_MRAGENT_DATA_SUCCESS,EDIT_MRAGENT_DATA} from './constants';
import {DELETE_EMPTY_MRAGENT_FAILED,DELETE_MRAGENT_DATA_SUCCESS,DELETE_MRAGENT_DATA} from './constants';
export const mrAgentData = (mrAgentData) => ({
    type: MRAGENT_DATA,
    payload: mrAgentData,
});

export const mrAgentSuccess = (mrAgentData) => ({
    type: MRAGENT_DATA_SUCCESS,
    payload: mrAgentData,
});

export const mrAgentFailed = (error) => ({
    type: EMPTY_MRAGENT_FAILED,
    payload: error,
});


export const postMrAgentData = (mrAgentData) => ({
    type: POST_MRAGENT_DATA,
    payload: mrAgentData,
});

export const postMrAgentSuccess = (mrAgentData) => ({
    type: POST_MRAGENT_DATA_SUCCESS,
    payload: mrAgentData,
});

export const postMrAgentFailed = (error) => ({
    type: POST_EMPTY_MRAGENT_FAILED,
    payload: error,
});
export const editMrAgentData = (mrAgentData) => ({
    type: EDIT_MRAGENT_DATA,
    payload: mrAgentData,
});

export const editMrAgentSuccess = (mrAgentData) => ({
    type: EDIT_MRAGENT_DATA_SUCCESS,
    payload: mrAgentData,
});

export const editMrAgentFailed = (error) => ({
    type: EDIT_EMPTY_MRAGENT_FAILED,
    payload: error,
});

export const deleteMrAgentData = (mrAgentData) => ({
    type: DELETE_MRAGENT_DATA,
    payload: mrAgentData,
});

export const deleteMrAgentSuccess = (mrAgentData) => ({
    type: DELETE_MRAGENT_DATA_SUCCESS,
    payload: mrAgentData,
});

export const deleteMrAgentFailed = (error) => ({
    type: DELETE_EMPTY_MRAGENT_FAILED,
    payload: error,
});