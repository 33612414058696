import { all, call, fork, takeLatest, put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { CALLREPORT_DATA } from './constants';
import { callReportFailed, callReportSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* callReport({payload}) {
    let filterData = ''
    if (payload === 'all') {
        filterData = '?all=true'
    } else if (payload) {
        filterData = payload.searchData ? `${payload.searchData}&page=${payload.pageNumber}&perPage=${payload.perPage}` : `?page=${payload.pageNumber}&perPage=${payload.perPage}`
    }
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield callReport(fetchJSON, config.backendPath + '/user'+filterData , options);
        yield put(callReportSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(callReportFailed(message));
        console.log(message);
    }
}

export function* watchVisit() {
    yield takeLatest(CALLREPORT_DATA, callReport);
}

function* callReportSaga() {
    yield all([fork(watchVisit),]);
}
export default callReportSaga;