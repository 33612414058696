export const PAGINATION_VISIT_DATA = 'PAGINATION_VISIT_DATA';
export const PAGINATION_VISIT_DATA_SUCCESS = 'PAGINATION_VISIT_DATA_SUCCESS';
export const PAGINATION_EMPTY_VISIT_FAILED = 'PAGINATION_EMPTY_VISIT_FAILED';
export const GET_VISIT_DATA = 'GET_VISIT_DATA';
export const GET_VISIT_DATA_SUCCESS = 'GET_VISIT_DATA_SUCCESS';
export const GET_VISIT_DATA_FAILED = 'GET_VISIT_DATA_FAILED';
export const GET_EMPTY_VISIT_FAILED = 'GET_EMPTY_VISIT_FAILED';
export const POST_VISIT_DATA = 'POST_VISIT_DATA';
export const POST_VISIT_DATA_SUCCESS = 'POST_VISIT_DATA_SUCCESS';
export const POST_EMPTY_VISIT_FAILED = 'POST_EMPTY_VISIT_FAILED';
export const EDIT_VISIT_DATA = 'EDIT_VISIT_DATA';
export const EDIT_VISIT_DATA_SUCCESS = 'EDIT_VISIT_DATA_SUCCESS';
export const EDIT_EMPTY_VISIT_FAILED = 'EDIT_EMPTY_VISIT_FAILED';
export const DELETE_VISIT_DATA = 'DELETE_VISIT_DATA';
export const DELETE_VISIT_DATA_SUCCESS = 'DELETE_VISIT_DATA_SUCCESS';
export const DELETE_EMPTY_VISIT_FAILED = 'DELETE_EMPTY_VISIT_FAILED'