import { all, call, fork, takeLatest, put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { GET_ATTENDANCE_DATA, PAGINATION_ATTENDANCE_DATA} from './constants';
import { attendanceFailed, attendanceSuccess } from './actions';
import { getAttendanceFailed, getAttendanceSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
import moment from 'moment'
function* attendance({payload}) {
    
    let filterData = ''
    if (payload === 'all') {
        filterData = '?all=true'
    } else if (payload) {
         filterData = payload.searchData ?   `?search=${payload.searchData}&page=${payload.pageNumber}&perPage=${payload.perPage}` 
         : payload.searchWith  ? `${payload.searchWith}&page=${payload.pageNumber}&perPage=${payload.perPage}`   
         : `?page=${payload.pageNumber}&perPage=${payload.perPage}`
    }
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET', 
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/getPaginatedAttendance'+filterData , options);
        if (response != null && response.data) {
            response.data.forEach((item) => {                
                if (item && item.startTime) {

                    item.startTime = moment(new Date(item.startTime)).format("DD-MM-YYYY h:mm a")

                }
                if (item && item.endTime) {
                    item.endTime=moment(new Date(item.endTime)).format("DD-MM-YYYY h:mm a")
                }
            })
        }
        yield put(attendanceSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(attendanceFailed(message));
        console.log(message);
    }
}

export function* watchVisit() {
    yield takeLatest(PAGINATION_ATTENDANCE_DATA, attendance);
}

function* attendanceSaga() {
    yield all([fork(watchVisit),]);
}
export default attendanceSaga;

function* getAttendance(req) {    
    const query = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/attendance' +query, options);              
        yield put(getAttendanceSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getAttendanceFailed(message));
        console.log(message);
    }
}

export function* watchGetAttendance() {
    yield takeLatest(GET_ATTENDANCE_DATA, getAttendance);
}

export function* getAttendanceSaga() {
    yield all([fork(watchGetAttendance),]);
} 