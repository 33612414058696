import { LOCATION_DATA, LOCATION_DATA_SUCCESS ,LOCATION_DATA_FAILED} from './constants';

export const locationData = (queryString) => ({
    type: LOCATION_DATA,
    payload: queryString,
});

export const locationSuccess = (locationData) => ({
    type: LOCATION_DATA_SUCCESS,
    payload: locationData,
});

export const locationFailed = (error) => ({
    type: LOCATION_DATA_FAILED,
    payload: error,
});