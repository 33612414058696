import {EMPTY_TENANT_FAILED,TENANT_DATA_SUCCESS,TENANT_DATA} from './constants';
import {POST_EMPTY_TENANT_FAILED,POST_TENANT_DATA_SUCCESS,POST_TENANT_DATA} from './constants';
import {EDIT_EMPTY_TENANT_FAILED,EDIT_TENANT_DATA_SUCCESS,EDIT_TENANT_DATA} from './constants';
import {DELETE_EMPTY_TENANT_FAILED,DELETE_TENANT_DATA_SUCCESS,DELETE_TENANT_DATA} from './constants';   
const INIT_STATE = [];

const Tenant = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TENANT_DATA:
            return { ...state, loading: true }
        case TENANT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null }
        case EMPTY_TENANT_FAILED:
            return { ...state, error: action.payload, loading: false }
        case POST_TENANT_DATA:
            return { ...state, loading: true }
        case POST_TENANT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null }
        case POST_EMPTY_TENANT_FAILED:
            return { ...state, error: action.payload, loading: false }
        case EDIT_TENANT_DATA:
            return { ...state, loading: true }
        case EDIT_TENANT_DATA_SUCCESS:
            return { ...state, loading: false, error: null }
        case EDIT_EMPTY_TENANT_FAILED:
            return { ...state, error: action.payload, loading: false }
        case DELETE_TENANT_DATA:
            return { ...state, loading: true }
        case DELETE_TENANT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null }
        case DELETE_EMPTY_TENANT_FAILED:
            return { ...state, error: action.payload, loading: false }
        default:
            return state
    }
};

export default Tenant;
