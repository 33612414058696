import { all, call, fork, takeLatest , put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import {MRAGENT_DATA} from './constants';
import {POST_MRAGENT_DATA} from './constants';
import {EDIT_MRAGENT_DATA} from './constants';
import {DELETE_MRAGENT_DATA} from './constants';
import { mrAgentFailed, mrAgentSuccess } from './actions';
import {  deleteMrAgentSuccess, postMrAgentSuccess } from './actions';
//import { editMrAgentFailed, editMrAgentSuccess } from './actions';
//import { deleteMrAgentFailed,postMrAgentFailed } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* mrAgent() {
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/user', options);
        yield put(mrAgentSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(mrAgentFailed(message));
        console.log(message);
    }
}

export function* watchMrAgent() {
    yield takeLatest (MRAGENT_DATA, mrAgent);
}

function* postMrAgent(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: JSON.stringify(body),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/user', options);
        yield put(postMrAgentSuccess(response));
       
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(mrAgentFailed(message));
        console.log(message);
    }
}
export function* watchPostMrAgent() {
    yield takeLatest (POST_MRAGENT_DATA, postMrAgent);
}

function* editMrAgent(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: JSON.stringify(body),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/user', options);
        yield put(postMrAgentSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(mrAgentFailed(message));
        console.log(message);
    }
}
export function* watchEditMrAgent() {
    yield takeLatest (EDIT_MRAGENT_DATA, editMrAgent);
}

function* deletetMrAgent(req) {
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = { 
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/user' + req.payload, options);
        yield put(deleteMrAgentSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(mrAgentFailed(message));
        console.log(message);
    }
}
export function* watchDeleteMrAgent() {
    yield takeLatest (DELETE_MRAGENT_DATA, deletetMrAgent);
}

function* mrAgentSaga() {
    yield all([fork(watchMrAgent),fork(watchPostMrAgent),fork(watchEditMrAgent),fork(watchDeleteMrAgent)]);
}

export default mrAgentSaga;