import { PAGINATION_ATTENDANCE_DATA, PAGINATION_ATTENDANCE_DATA_SUCCESS ,PAGINATION_ATTENDANCE_DATA_FAILED} from './constants';
import { GET_ATTENDANCE_DATA, GET_ATTENDANCE_DATA_SUCCESS ,GET_ATTENDANCE_DATA_FAILED} from './constants';

export const attendanceData = (queryString) => ({
    type: PAGINATION_ATTENDANCE_DATA,
    payload: queryString,
});

export const attendanceSuccess = (attendanceData) => ({
    type: PAGINATION_ATTENDANCE_DATA_SUCCESS,
    payload: attendanceData,
});

export const attendanceFailed = (error) => ({
    type: PAGINATION_ATTENDANCE_DATA_FAILED,
    payload: error,
});
export const getAttendanceData = (queryString) => ({
    type: GET_ATTENDANCE_DATA,
    payload: queryString,
});

export const getAttendanceSuccess = (attendanceData) => ({
    type: GET_ATTENDANCE_DATA_SUCCESS,
    payload: attendanceData,
});

export const getAttendanceFailed = (error) => ({
    type: GET_ATTENDANCE_DATA_FAILED,
    payload: error,
});