import { all, call, fork, takeLatest, put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { LOCATION_DATA } from './constants';
import { locationFailed, locationSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';

function* getLocation(action) {
    let payload = action.payload ? action.payload : ''
    const userdata = yield getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/userlocation' + payload, options);
        if (response) {
            yield put(locationSuccess(response));
        } else {
            yield put(locationFailed(response));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(locationFailed(message));
        console.log(message);
    }
}

export function* watchlocation() {
    yield takeLatest(LOCATION_DATA, getLocation);
}

function* locationSaga() {
    yield all([fork(watchlocation)]);
}

export default locationSaga;