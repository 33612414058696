import { EMPTY_SMS_FAILED, SMS_DATA_SUCCESS, SMS_DATA } from './constants';
const INIT_STATE = [];

const Sms = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SMS_DATA:
            return { ...state, loading: true };
        case SMS_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case EMPTY_SMS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export default Sms;
