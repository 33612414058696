import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import PreLoaderWidget from '../components/Loader';
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const MrAgent = React.lazy(() => import('../pages/mrAgent'));
const Visit = React.lazy(() => import('../pages/visit'));
const doctor = React.lazy(() => import('../pages/doctor'));
const Tenant = React.lazy(() => import('../pages/tenant'));
const Sms = React.lazy(() => import('../pages/sms'));
const locationReport = React.lazy(() => import('../pages/reports/locationReport'))
const CallReport = React.lazy(() => import('../pages/reports/callReport'));
const AttendanceReport = React.lazy(() => import('../pages/reports/attendanceReport'));
const CurrentLocation = React.lazy(() => import('../pages/reports/currentLocation'));
const Profile = React.lazy(() => import('../pages/profile/index'));
const loggedInUser = getLoggedInUser();
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
    
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }
           
            if (loggedInUser && roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }
            props.user = loggedInUser
            return <Component {...props} />;
        }}
    />
);

const rootRoute = {
    path: '/',
    exact: true,
    component: loggedInUser && loggedInUser.role == 'SuperAdmin' ? () => <Redirect to="/tenant" />:() => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};       
const dashboardRoutes = {
    path: '/dashboard',
    name: 'dashboard', 
    icon: FeatherIcon.Home,
    component: Dashboard,
    roles: ['Admin', 'Manager'],
    route: PrivateRoute
};

const visitRoutes = {
    path: '/visit',
    name: 'visit',
    icon: FeatherIcon.MapPin,
    component: Visit,
    roles: ['Admin', 'Manager' ],
    route: PrivateRoute
};

const mrAgentRoutes = {
    path: '/mrAgent',
    name: 'user',
    icon: FeatherIcon.User,
    component: MrAgent,
    roles: ['Admin', 'Manager'],
    route: PrivateRoute
};

const reportRoutes = {
    path: '/report',
    name: 'Reports',
    icon: FeatherIcon.FileText,
    children: [
        // {
        //     path: '/report/callReport',
        //     name: 'Call Report',
        //     icon: FeatherIcon.Phone,
        //     component: CallReport,
        //     route: PrivateRoute,
        //     roles: ['Admin', "SuperAdmin"],
        // },
        {
            path: '/report/locationReport',
            name: 'locationReport',
            icon: FeatherIcon.Map,
            component: locationReport,
            route: PrivateRoute,
            roles: ['Admin', 'Manager'],
        },
        {
            path: '/report/attendanceReport',
            name: 'attendanceReport',
            icon: FeatherIcon.CheckSquare,
            component: AttendanceReport,
            route: PrivateRoute,
            roles: ['Admin', 'Manager'],
        },
        {
            path: '/report/currentLocation',
            name: 'currentLocation',
            component: CurrentLocation,
            icon: FeatherIcon.Navigation2,
            route: PrivateRoute,
            roles: ['Admin', 'Manager'],
        },
    ],
    roles: ['Admin','Manager'],
};

const appRoutes = [reportRoutes];

const doctortRoutes = {
    path: '/location',
    name: 'location',
    icon: FeatherIcon.Activity,
    component: doctor,
    roles: ['Admin','Manager'],
    route: PrivateRoute
};

const tenantRoutes = {
    path: '/tenant',
    name: 'Tenant',
    icon: FeatherIcon.Users,
    component: Tenant,
    roles: ["SuperAdmin"],
    route: PrivateRoute
};

// const smsRoutes = {
//     path: '/sms',
//     name: 'Sms',
//     icon: FeatherIcon.MessageSquare,
//     component: Sms,
//     roles: [ ],
//     route: PrivateRoute
// };

const ProfileRoute = {
    path: '/profile',
    name: 'profile',
    icon: FeatherIcon.Users,
    component: Profile,
    route: PrivateRoute
};
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        // {
        //     path: '/account/register',
        //     name: 'Register',
        //     component: Register,
        //     route: Route,
        // },
        // {
        //     path: '/account/confirm',
        //     name: 'Confirm',
        //     component: Confirm,
        //     route: Route,
        // },
        // {
        //     path: '/account/forget-password',
        //     name: 'Forget Password',
        //     component: ForgetPassword,
        //     route: Route,
        // },
    ],
};

const flattenRoutes = routes => {
    
    let flatRoutes = [];
    routes = routes || [];
    // console.log(routes);
    routes.forEach(item => {
        flatRoutes.push(item);
        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    let loggedInUser = getLoggedInUser();
    if (loggedInUser && loggedInUser.skin && loggedInUser.skin.constructor === Object && Object.keys(loggedInUser.skin).length !== 0) {
        flatRoutes.forEach(el => {
            for (const [name, value] of Object.entries(loggedInUser.skin)) {
                //  console.log( flatRoutes.report);
                if (name === el.name) {
                    if (el.path.startsWith("/report")) {
                        el.path = ('/report/' + value).replace(/ /g, '')
                    }
                    else {
                        el.path = '/' + value.replace(/ /g, '')
                    }
                     el.name = value 
                     return el
                }
            }
        })
    }
    return flatRoutes;
};

const allRoutes = [
    rootRoute,
    dashboardRoutes,
    doctortRoutes,
    mrAgentRoutes,
    visitRoutes,
  
    tenantRoutes,
    // smsRoutes,
    ...appRoutes,
    authRoutes,
    ProfileRoute
];

const authProtectedRoutes = [
    dashboardRoutes,
    doctortRoutes,
    mrAgentRoutes,
    visitRoutes,
    tenantRoutes,
    // smsRoutes,
    ...appRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
