import { PAGINATION_EMPTY_VISIT_FAILED, PAGINATION_VISIT_DATA_SUCCESS, PAGINATION_VISIT_DATA } from './constants';
import { GET_EMPTY_VISIT_FAILED, GET_VISIT_DATA_SUCCESS, GET_VISIT_DATA } from './constants';
import { POST_EMPTY_VISIT_FAILED, POST_VISIT_DATA_SUCCESS, POST_VISIT_DATA } from './constants';
import { EDIT_EMPTY_VISIT_FAILED, EDIT_VISIT_DATA_SUCCESS, EDIT_VISIT_DATA } from './constants';
import { DELETE_EMPTY_VISIT_FAILED, DELETE_VISIT_DATA_SUCCESS, DELETE_VISIT_DATA } from './constants';
const INIT_STATE = {};

const Visit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'INITIAL_STATE':
            return { ...state, data: [], loading: true };
        case PAGINATION_VISIT_DATA:
            return { ...state, loading: true };
        case PAGINATION_VISIT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case PAGINATION_EMPTY_VISIT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_VISIT_DATA:
            return { ...state, loading: true , error: null};
        case GET_VISIT_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case GET_EMPTY_VISIT_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

// export const PostVisit = (state = INIT_STATE, action) => {
//     switch (action.type) {
//         case POST_VISIT_DATA:
//             return { ...state, loading: true };
//         case POST_VISIT_DATA_SUCCESS:
//             return { ...state, data: action.payload, loading: false, error: null };
//         case POST_EMPTY_VISIT_FAILED:
//             return { ...state, error: action.payload, loading: false };
//         default:
//             return state;
//     }
// };

// export const EditVisit = (state = INIT_STATE, action) => {
//     switch (action.type) {
//         case EDIT_VISIT_DATA:
//             return { ...state, loading: true };
//         case EDIT_VISIT_DATA_SUCCESS:
//             return { ...state, data: action.payload, loading: false, error: null };
//         case EDIT_EMPTY_VISIT_FAILED:
//             return { ...state, error: action.payload, loading: false };
//         default:
//             return state;
//     }
// };

// export const DeleteVisit = (state = INIT_STATE, action) => {
//     switch (action.type) {
//         case DELETE_VISIT_DATA:
//             return { ...state, loading: true };
//         case DELETE_VISIT_DATA_SUCCESS:
//             return { ...state, data: action.payload, loading: false, error: null };
//         case DELETE_EMPTY_VISIT_FAILED:
//             return { ...state, error: action.payload, loading: false };
//         default:
//             return state;
//     }
// };

export default Visit;
