import { all, call, fork, takeLatest, put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import { PAGINATION_VISIT_DATA } from './constants';
import { GET_VISIT_DATA } from './constants';
import { POST_VISIT_DATA } from './constants';
import { EDIT_VISIT_DATA } from './constants';
import { DELETE_VISIT_DATA } from './constants';
import { visitFailed, visitSuccess } from './actions';
import { postVisitSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
import moment from 'moment'
function* visit({ payload }) {
    let filterData = ''
    if (payload === 'all') {
        filterData = '?all=true'
    } else if (payload) {
        filterData = payload.searchData ? `?search=${payload.searchData}&page=${payload.pageNumber}&perPage=${payload.perPage}`
            : payload.searchWith ? `${payload.searchWith}&page=${payload.pageNumber}&perPage=${payload.perPage}`
                : `?page=${payload.pageNumber}&perPage=${payload.perPage}`
    }

    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/getPaginatedVisit' + filterData, options);
        if (response && response.data) {
            response.data.forEach((item) => {
                if (item && item.isManualVisit) {
                    item.isManualVisit = "Manual"
                }
                else {
                    item.isManualVisit = "Auto"
                }
            })
            response.data.forEach((item) => {
                if (item && item.startTime) {

                    item.startTime = moment(new Date(item.startTime)).format("DD-MM-YYYY h:mm a")

                }
                if (item && item.endTime) {
                    item.endTime=moment(new Date(item.endTime)).format("DD-MM-YYYY h:mm a")
                }
            })
        }

        yield put(visitSuccess(response));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(visitFailed(message));
        console.log(message);
    }
}

export function* watchVisit() {
    yield takeLatest(PAGINATION_VISIT_DATA, visit);
}

function* visitSaga() {
    yield all([fork(watchVisit), fork(watchGetVisit)]);
}


function* getVisit(req) {
    const query = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/visit' + query, options);

        yield put(visitSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(visitFailed(message));
        console.log(message);
    }
}

export function* watchGetVisit() {
    yield takeLatest(GET_VISIT_DATA, getVisit);
}

export function* getVisitSaga() {
    yield all([fork(watchGetVisit),]);
}


//Post api

function* postVisit(req) {
    const body = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/visit', options);
        yield put(postVisitSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(visitFailed(message));
        console.log(message);
    }
}
export function* watchPostVisit() {
    yield takeLatest(POST_VISIT_DATA, postVisit);
}

export function* postVisitSaga() {
    yield all([fork(watchPostVisit),]);
}

//edit api
function* editVisit(req) {
    const body = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'EDIT',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/visit', options);
        yield put(postVisitSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(visitFailed(message));
        console.log(message);
    }
}
export function* watchEditVisit() {
    yield takeLatest(EDIT_VISIT_DATA, editVisit);
}
export function* editVisitSaga() {
    yield all([fork(watchEditVisit),]);
}

//Delete api

function* deletetVisit(req) {
    const body = req.payload ? req.payload : {}
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: body,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/visit', options);
        yield put(postVisitSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(visitFailed(message));
        console.log(message);
    }
}
export function* watchDeleteVisit() {
    yield takeLatest(DELETE_VISIT_DATA, deletetVisit);
}
export function* deleteVisitSaga() {
    yield all([fork(watchDeleteVisit),]);
}
export default visitSaga;