import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Dashboard from './dashboard/reducers';
import MrAgent from './mrAgent/reducers';
import location from  './locationReport/reducers';
import Doctor from './doctor/reducers';
import Visit from './visit/reducers';
import Sms from './sms/reducers';
import Tenant from './tenant/reducers';
import Attendance from './attendanceReport/reducers';
import Call from './callReport/reducers';
import ActiveLocation from './activelocation/reducers';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Dashboard,
    MrAgent,
    Sms,
    Doctor,
    Visit,
    Tenant,
    Attendance,
    Call,
    ActiveLocation,
    location
});
