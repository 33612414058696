// @flow
import { DASHBOARD_DATA, DASHBOARD_DATA_SUCCESS, DASHBOARD_DATA_FAILED } from './constants';
const INIT_STATE = {};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_DATA:
            return { ...state, loading: true };
        case DASHBOARD_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case DASHBOARD_DATA_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};
export default Dashboard;
