import { EMPTY_SMS_FAILED, SMS_DATA_SUCCESS, SMS_DATA } from './constants';
export const smsData = (smsData) => ({
    type: SMS_DATA,
    payload: smsData,
});

export const smsSuccess = (smsData) => ({
    type: SMS_DATA_SUCCESS,
    payload: smsData,
});

export const smsFailed = (error) => ({
    type: EMPTY_SMS_FAILED,
    payload: error,
});
