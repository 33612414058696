// @flow
import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import { getLoggedInUser } from '../../helpers/authUtils';
import { INIT_MENU, CHANGE_ACTIVE_MENU_FROM_LOCATION } from './constants';
import { authProtectedRoutes as routes } from '../../routes';
import assignIds from './utils';
import { initMenuSuccess, changeActiveMenuFromLocationSuccess } from './actions';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */

const authroutes = routes => {
    let loggedInUser = getLoggedInUser();
    if (loggedInUser && loggedInUser.skin && loggedInUser.skin.constructor === Object && Object.keys(loggedInUser.skin).length !== 0) {

        routes.forEach(el => {
            for (const [name, value] of Object.entries(loggedInUser.skin)) {
                if (name === el.name) {
                    if (el.path.startsWith("/report")) {
                        el.path = ('/report/' + value).replace(/ /g, '')
                    }
                    else {
                        el.path = '/' + value.replace(/ /g, '')
                    }
                    el.name = value
                    return el
                }
            }
        })
    }
    return routes;
}

const getActivatedMenuItemIds = menuItems => {
    var matchingItems = [];
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(menuItem.path) === 0) {
            matchingItems.push(menuItem.id);
        }
        if (typeof menuItem.children !== 'undefined') {
            matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
        }
    }
    return matchingItems;
};

/**
 * Initilizes the menu
 */
function* initMenuAndItems() {
    try {
        const skinRoute = authroutes(routes)
        const menuItems = assignIds(skinRoute);
        yield put(initMenuSuccess(menuItems));
    } catch (error) { }
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation() {
    try {
        const skinRoute = authroutes(routes)
        const menuItems = assignIds(skinRoute);
        const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems);
        yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
    } catch (error) { }
}

/**
 * Watchers
 */
export function* watchMenuInit() {
    yield takeLatest(INIT_MENU, initMenuAndItems);
}

export function* watchMenuChange() {
    yield takeLatest(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

function* appMenuSaga() {
    yield all([fork(watchMenuInit), fork(watchMenuChange)]);
}

export default appMenuSaga;
