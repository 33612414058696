import React,{ useEffect }  from 'react';
import { BrowserRouter,withRouter ,Switch } from 'react-router-dom';
import {  useDispatch } from 'react-redux';
// layout HOC
import withLayout from '../components/Layout';
import { allFlattenRoutes as routes } from './index';
// const Error404 = React.lazy(() => import('../pages/other/Error404'));
const RouteChange = withRouter(({ location }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch({ type: "INITIAL_STATE" });
    }, [dispatch, location.pathname]);
    return null;
  });
const Routes = () => (

    <BrowserRouter >
        <RouteChange></RouteChange>
        <Switch>
            {routes.map((route, index) => {
                return (
                    !route.children && ( <route.route key={index}
                            path={route.path}
                            roles={route.roles}
                            exact={route.exact}
                            component={withLayout(props => {
                                return <route.component {...props} />;
                            })}></route.route>
                    )
                );
            })}
            {/* <BrowserRouter path="/404" component={Error404} />
             <BrowserRouter path='*' component={Error404} /> */}
        </Switch>
    </BrowserRouter>
);

export default Routes;