import {EMPTY_TENANT_FAILED,TENANT_DATA_SUCCESS,TENANT_DATA} from './constants';
import {POST_EMPTY_TENANT_FAILED,POST_TENANT_DATA_SUCCESS,POST_TENANT_DATA} from './constants';
import {EDIT_EMPTY_TENANT_FAILED,EDIT_TENANT_DATA_SUCCESS,EDIT_TENANT_DATA} from './constants';
import {DELETE_EMPTY_TENANT_FAILED,DELETE_TENANT_DATA_SUCCESS,DELETE_TENANT_DATA} from './constants';
export const tenantData = (tenantData) => ({
    type: TENANT_DATA,
    payload: tenantData,
});

export const tenantSuccess = (tenantData) => ({
    type: TENANT_DATA_SUCCESS,
    payload: tenantData,
});

export const tenantFailed = (error) => ({
    type: EMPTY_TENANT_FAILED,
    payload: error,
});


export const postTenantData = (tenantData) => ({
    type: POST_TENANT_DATA,
    payload: tenantData,
});

export const postTenantSuccess = (tenantData) => ({
    type: POST_TENANT_DATA_SUCCESS,
    payload: tenantData,
});

export const postTenantFailed = (error) => ({
    type: POST_EMPTY_TENANT_FAILED,
    payload: error,
});

export const editTenantData = (tenantData) => ({
    type: EDIT_TENANT_DATA,
    payload: tenantData,
});

export const editTenantSuccess = (tenantData) => ({
    type: EDIT_TENANT_DATA_SUCCESS,
    payload: tenantData,
});

export const editTenantFailed = (error) => ({
    type: EDIT_EMPTY_TENANT_FAILED,
    payload: error,
});

export const deleteTenantData = (tenantData) => ({
    type: DELETE_TENANT_DATA,
    payload: tenantData,
});

export const deleteTenantSuccess = (tenantData) => ({
    type: DELETE_TENANT_DATA_SUCCESS,
    payload: tenantData,
});

export const deleteTenantFailed = (error) => ({
    type: DELETE_EMPTY_TENANT_FAILED,
    payload: error,
});