import {EMPTY_DOCTOR_FAILED,DOCTOR_DATA_SUCCESS,DOCTOR_DATA} from './constants';
import {POST_EMPTY_DOCTOR_FAILED,POST_DOCTOR_DATA_SUCCESS,POST_DOCTOR_DATA} from './constants';
import {EDIT_EMPTY_DOCTOR_FAILED,EDIT_DOCTOR_DATA_SUCCESS,EDIT_DOCTOR_DATA} from './constants';
import {DELETE_EMPTY_DOCTOR_FAILED,DELETE_DOCTOR_DATA_SUCCESS,DELETE_DOCTOR_DATA} from './constants';
export const doctorData = (doctorData) => ({
    type: DOCTOR_DATA,
    payload: doctorData,
});

export const doctorSuccess = (doctorData) => ({
    type: DOCTOR_DATA_SUCCESS,
    payload: doctorData,
});

export const doctorFailed = (error) => ({
    type: EMPTY_DOCTOR_FAILED,
    payload: error,
});


export const postDoctorData = (doctorData) => ({
    type: POST_DOCTOR_DATA,
    payload: doctorData,
});

export const postDoctorSuccess = (doctorData) => ({
    type: POST_DOCTOR_DATA_SUCCESS,
    payload: doctorData,
});

export const postDoctorFailed = (error) => ({
    type: POST_EMPTY_DOCTOR_FAILED,
    payload: error,
});

export const editDoctorData = (doctorData) => ({
    type: EDIT_DOCTOR_DATA,
    payload: doctorData,
});

export const editDoctorSuccess = (doctorData) => ({
    type: EDIT_DOCTOR_DATA_SUCCESS,
    payload: doctorData,
});

export const editDoctorFailed = (error) => ({
    type: EDIT_EMPTY_DOCTOR_FAILED,
    payload: error,
});

export const deletedoctorData = (doctorData) => ({
    type: DELETE_DOCTOR_DATA,
    payload: doctorData,
});

export const deletedoctorSuccess = (doctorData) => ({
    type: DELETE_DOCTOR_DATA_SUCCESS,
    payload: doctorData,
});

export const deletedoctorFailed = (error) => ({
    type: DELETE_EMPTY_DOCTOR_FAILED,
    payload: error,
});