import { all, call, fork, takeLatest , put } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { config } from '../../helpers/config'
import {DOCTOR_DATA} from './constants';
import {POST_DOCTOR_DATA} from './constants';
import {EDIT_DOCTOR_DATA} from './constants';
import {DELETE_DOCTOR_DATA} from './constants';
import { doctorFailed, doctorSuccess } from './actions';
import {  postDoctorSuccess } from './actions';
//import { postDoctorFailed, editDoctorFailed, editDoctorSuccess } from './actions';
//import { deleteDoctorFailed, deleteDoctorSuccess } from './actions';
import { getLoggedInUser } from '../../helpers/authUtils';
function* getDoctor({payload}) {
    let filterData = ''
    if (payload === 'all') {
        filterData = '?all=true'
    } else if (payload) {
        filterData = payload.searchData ? `?search=${payload.searchData}&page=${payload.pageNumber}&perPage=${payload.perPage}` : `?page=${payload.pageNumber}&perPage=${payload.perPage}`
    }
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: null,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        let response = yield call(fetchJSON, config.backendPath + '/getPaginatedDoctor'+filterData, options);
        if(response && response.length){
            response = response[0]
            yield put(doctorSuccess(response))
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(doctorFailed(message));
        console.log(message);
    }
}

export function* watchDoctor() {
    yield takeLatest (DOCTOR_DATA, getDoctor);
}

function* postDoctor(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: JSON.stringify(body),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/doctor', options);
        yield put(postDoctorSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(doctorFailed(message));
        console.log(message);
    }
}
export function* watchPostDoctor() {
    yield takeLatest (POST_DOCTOR_DATA, postDoctor);
}
function* editDoctor(req) {
    const body = req.payload ?req.payload : {} 
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = {
        body: JSON.stringify(body),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
    try {
        const response = yield call(fetchJSON, config.backendPath + '/doctor', options);
        yield put(postDoctorSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(doctorFailed(message));
        console.log(message);
    }
}
export function* watchEditDoctor() {
    yield takeLatest (EDIT_DOCTOR_DATA, editDoctor);
}


//Delete api

function* deletetDoctor(req) {
    const userdata = getLoggedInUser()
    let bearer = ''
    if (userdata && userdata.token) {
        bearer = 'bearer ' + userdata.token
    }
    const options = { 
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: bearer },
    };
  
    try {
        const response = yield call(fetchJSON, config.backendPath + '/doctor'+ req.payload, options);
        yield put(postDoctorSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(doctorFailed(message));
        console.log(message);
    }
}
export function* watchDeleteDoctor() {
    yield takeLatest (DELETE_DOCTOR_DATA, deletetDoctor);
}



function* doctorSaga() {
    yield all([fork(watchDoctor),fork(watchPostDoctor),fork(watchEditDoctor),fork(watchDeleteDoctor)]);
}

export default doctorSaga;