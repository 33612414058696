export const ActiveLocation_DATA = 'ActiveLocation_DATA';
export const ActiveLocation_DATA_SUCCESS = 'ActiveLocation_DATA_SUCCESS';
export const EMPTY_ActiveLocation_FAILED = 'EMPTY_ActiveLocation_FAILED';
export const POST_ActiveLocation_DATA = 'POST_ActiveLocation_DATA';
export const POST_ActiveLocation_DATA_SUCCESS = 'POST_ActiveLocation_DATA_SUCCESS';
export const POST_EMPTY_ActiveLocation_FAILED = 'POST_EMPTY_ActiveLocation_FAILED';
export const EDIT_ActiveLocation_DATA = 'EDIT_ActiveLocation_DATA';
export const EDIT_ActiveLocation_DATA_SUCCESS = 'EDIT_ActiveLocation_DATA_SUCCESS';
export const EDIT_EMPTY_ActiveLocation_FAILED = 'EDIT_EMPTY_ActiveLocation_FAILED';
export const DELETE_ActiveLocation_DATA = 'DELETE_ActiveLocation_DATA';
export const DELETE_ActiveLocation_DATA_SUCCESS = 'DELETE_ActiveLocation_DATA_SUCCESS';
export const DELETE_EMPTY_ActiveLocation_FAILED = 'DELETE_EMPTY_ActiveLocation_FAILED'